<template>
  <div class="comm-container">
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
import Footer from '../../components/common/Footer';
import { nav } from '../../data/nav';
export default {
  components : {
    Footer
  },

  data() {
    return {
      nav
    }
  }
}
</script>

<style>

</style>